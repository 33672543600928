import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion, AnimatePresence } from 'framer-motion';
import shuffle from 'lodash.shuffle';

import bootstrap from '../images/logos/bootstrap.png';
import css from '../images/logos/css.png';
import digitalOcean from '../images/logos/digital-ocean2.png';
import html from '../images/logos/html.png';
import jquery from '../images/logos/jquery.png';
import js from '../images/logos/js.png';
import mongodb from '../images/logos/mongodb.png';
import nginx from '../images/logos/nginx.png';
import node from '../images/logos/node.png';
import python from '../images/logos/python.png';
import react from '../images/logos/react.png';
import redux from '../images/logos/redux.png';
import aws from '../images/logos/aws.png';
import wordpress from '../images/logos/wordpress.png';
import sassLess from '../images/logos/sass-less.png';
import azureDevops from '../images/logos/azure-devops.png';
import docker from '../images/logos/docker.png';
import postman from '../images/logos/postman.png';

const logos = [
  { img: html, title: 'HTML 5' },
  { img: css, title: 'CSS 3' },
  { img: sassLess, title: 'SASS / LESS' },
  { img: js, title: 'Javascript / ES6' },
  { img: jquery, title: 'jQuery' },
  { img: react, title: 'React' },
  { img: redux, title: 'Redux' },
  { img: bootstrap, title: 'Bootstrap 4' },
  { img: wordpress, title: 'Wordpress' },
  { img: node, title: 'Node JS' },
  { img: mongodb, title: 'MongoDB' },
  { img: python, title: 'Python 3' },
  { img: digitalOcean, title: 'Digital Ocean' },
  { img: nginx, title: 'NGinX' },
  { img: aws, title: 'Amazon Web Services' },
  { img: azureDevops, title: 'Azure Devops' },
  { img: docker, title: 'Docker' },
  { img: postman, title: 'Postman' },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: 'space-around',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
  },
  logo: {
    height: '30px',
    width: 'auto',
    margin: theme.spacing(0, 4, 4, 0),
  },
}));

export const LogoCloud = () => {
  const { logo, wrapper } = useStyles();
  const [counter, setCounter] = useState(0);
  const [items, setItems] = useState(logos);
  const increaseCounter = () => setCounter((i) => i + 1);
  useEffect(() => {
    if (counter > 2) {
      const newArrayOrder = shuffle(items);
      setCounter(0);
      setItems(newArrayOrder);
    }
  }, [counter, items]);
  return (
    <Grid container className={wrapper}>
      <AnimatePresence>
        {items.map(({ img, title }, index) => (
          <motion.img
            className={logo}
            src={img}
            alt={title}
            title={title}
            key={title}
            whileHover={{ scale: 0.9 }}
            whileTap={{ rotate: index % 2 === 0 ? 180 : -180 }}
            onClick={increaseCounter}
          />
        ))}
      </AnimatePresence>
    </Grid>
  );
};
