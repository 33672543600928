import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LandsattaImg from "../images/sites/landsatta-responsive.png";
import PetStoreImg from "../images/sites/petshop.png";
import LarrysImg from "../images/sites/larrys.png";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: "0 0 350px",
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
  },
  img: {
    height: "186px",
  },
}));

const sites = [
  {
    title: "Landsatta",
    description:
      "The Landsatta Demo is a landing page style website, with a modern multi-column layout. Featuring a full width hero image, customer reviews slider, and a contact form, Landsatta is simple, modern, and professional.",
    img: LandsattaImg,
    url: "https://tb-landsatta.netlify.app/",
  },
  {
    title: "Pet Store",
    description:
      "The Pet Store Demo is a bright and coloful website. The navigation menu switches from a sidebar to a top menu for easy navigation, and a newletter signup and social media links promotes community engagement.",
    img: PetStoreImg,
    url: "https://tb-pet-store.netlify.app/",
  },
  {
    title: "Larry's Corner Shop",
    description:
      "The Larry's Corner Shop Demo features a clean lines and striking colors. A store information modal makes critical information easily accessable, and product spotlights line the bottom of the website.",
    img: LarrysImg,
    url: "https://tb-larrys-corner-shop.netlify.app/",
  },
];

export const SiteDemos = () => {
  const { card, img } = useStyles();

  return (
    <Grid container justifyContent="space-around">
      {sites.map((site) => (
        <Card className={card} key={site.url}>
          <CardMedia className={img} image={site.img} title={site.title} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {site.title}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              paragraph
            >
              {site.description}
            </Typography>
            <Link href={site.url} target="_blank">
              View {site.title} Demo
            </Link>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
};
