import React from "react";

import SEO from "../components/seo";
import { Link } from "gatsby";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SiteDemos } from "../components/site-demos";
import { Projects } from "../components/projects-list";
import { LogoCloud } from "../components/logo-cloud";
import { ContactForm } from "../forms/contact-form";
import { IntroParagraph } from "../components/intro-paragraph";
import { motion } from "framer-motion";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => {
  return {
    section: {
      margin: theme.spacing(15, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(5, 0, 10, 0),
      },
    },
    bioLink: {
      ...theme.typography.button,
      textTransform: "unset",
      textDecoration: "none",
    },
    sectionTitle: {
      ...theme.typography.h4,
      color: theme.palette.grey[800],
    },
    skillsPanel: {
      flex: "0 0 400px",
      boxShadow: theme.shadows[1],
      margin: theme.spacing(2),
      padding: theme.spacing(2),
    },
  };
});

const IndexPage = () => {
  const { section, sectionTitle, bioLink } = useStyles();
  return (
    <>
      <SEO title="Professional Web Application Development" />
      <motion.div
        key="home"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
      >
        <Box component="section" className={section}>
          <IntroParagraph />
          <Box style={{ textAlign: "center" }}>
            <Link to="/about" className={bioLink}>
              <span>More about me</span>
              <KeyboardArrowRightIcon style={{ marginBottom: "-7px" }} />
            </Link>
          </Box>
        </Box>

        <Box component="section" className={section}>
          <Typography className={sectionTitle}>Projects</Typography>
          <Divider style={{ marginBottom: "30px" }} />
          <Projects limit={3} hideSearch />
        </Box>

        <Box component="section" className={section}>
          <Typography className={sectionTitle} c>
            Technology &amp; Skills
          </Typography>
          <Divider style={{ marginBottom: "30px" }} />
          <Grid container style={{ marginBottom: "80px" }} spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" color="textSecondary" paragraph>
                Core Technology
              </Typography>
              <Typography variant="body1" paragraph>
                I write high performance web apps using languages and frameworks
                that maximize the benefits of modern HTML, CSS and browser APIs.
                I focus on providing responsive and intuitive user interfaces by
                using modern ES6 Javascript, React, Material UI and other
                popular libraries. I also use the latest build and deploy tools
                such as Webpack and Lighthouse to test my code, ensure
                accessability standards are met, and encourage positive user
                interaction.
              </Typography>
              <Typography variant="body1">
                I build fast and scalable server infrastructure following
                industry standards using Node and MongoDB. I have experience
                writing custom APIs following the latest security standards, as
                well as intergrating custom services with third party APIs. I
                have experience deploying applications with the largest hosting
                providers, such as Digital Ocean, Microsoft Azure, and AWS, all
                while using modern containerization technologies like Docker.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" color="textSecondary" paragraph>
                Professional Skills
              </Typography>
              <Typography variant="body1" paragraph>
                I'm a highly motivated developer with more than five years of
                professional experience. I'm exceptionally personable and have
                the rare gift of being able to communicate technically,
                professionally, and socially. I excel at taking high-level ideas
                from clients and executives, then converting those ideas into
                actionable tasks that technical-minded developers can work from.
              </Typography>
              <Typography variant="body1" paragraph>
                I strive to exhibit a wide array of leadership skills in my
                daily routine, and I acknowledge that it takes many skills to
                build a sucessful product. I believe that careful planning and
                thoughtfulness promotes high quality web products. I think that
                diligent documentation benefits customers and employees by
                reducing confusion and spreading product knowledge. I believe
                that my timeliness, creative problem solving, and my ability to
                promote positive relationships will solidify my presence as a
                professional and as a leader.
              </Typography>
            </Grid>
          </Grid>
          <LogoCloud />
        </Box>

        <Box component="section" className={section}>
          <Typography className={sectionTitle}>Websites</Typography>
          <Divider style={{ marginBottom: "30px" }} />
          <SiteDemos />
        </Box>

        <Box component="section" className={section}>
          <Typography className={sectionTitle}>Contact Me</Typography>
          <Divider style={{ marginBottom: "30px" }} />
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
              <Typography variant="h5">Get in touch with me!</Typography>
              <Typography variant="body1" paragraph>
                Please complete the form below to get in touch with me.
              </Typography>
              <ContactForm />
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </>
  );
};

export default IndexPage;
